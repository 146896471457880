import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import notFoundIllustration from '../images/not-found.svg';

const NotFoundPage: React.FC = () => {
    return (
        <Layout>
            <SEO title='404: Not found' />
            <div className='text-center'>
                <img
                    alt='Ghost getting abducted by aliens'
                    className='block mx-auto w-1/2'
                    src={notFoundIllustration}
                />
                <h2 className='text-2xl font-bold inline-block my-8 p-3'>404</h2>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
