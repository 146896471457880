import React, { useState } from 'react';

import { graphql, useStaticQuery, Link } from 'gatsby';

const Header: React.FC = () => {
    const [isExpanded, toggleExpansion] = useState(false);
    const { site } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <header className='bg-white'>
            <div className='flex flex-wrap items-center justify-between max-w-6xl p-4 mx-auto md:p-8'>
                <Link to='/'>
                    <h1 className='flex items-center text-gray-700 no-underline'>
                        <span className='text-xl font-bold tracking-tight'>
                            {site.siteMetadata.title}
                        </span>
                        <svg
                            style={{
                                width: 32,
                                height: 32,
                                color: '#6c63fe',
                                marginLeft: -4,
                                marginTop: -8,
                            }}
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='#ff6484'
                        >
                            <path
                                fill-rule='evenodd'
                                d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z'
                                clip-rule='evenodd'
                            />
                        </svg>
                    </h1>
                </Link>

                <button
                    className='flex items-center block px-3 py-2 text-gray-700 border border-white rounded md:hidden'
                    onClick={() => toggleExpansion(!isExpanded)}
                >
                    <svg
                        className='w-3 h-3 fill-current'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <title>Menu</title>
                        <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                    </svg>
                </button>

                <nav
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } md:block md:flex md:items-center w-full md:w-auto`}
                >
                    {[
                        {
                            route: `https://app.care.primatech.id`,
                            title: `Login`,
                        },
                    ].map((link) => (
                        <Link
                            className='block mt-4 text-gray-700 no-underline md:inline-block md:mt-0 md:ml-6 rounded border border-solid border-primary px-4 py-1 text-primary text-bold'
                            key={link.title}
                            to={link.route}
                        >
                            {link.title}
                        </Link>
                    ))}
                </nav>
            </div>
        </header>
    );
};

export default Header;
