import React from 'react';

import Header from './header';

const Layout: React.FC = ({ children }) => {
    return (
        <div className='min-h-screen relative'>
            <div className='absolute inset-x-0 h-1 bg-accent' />
            <Header />

            <main>{children}</main>

            <footer className='absolute flex items-center justify-center bottom-0 inset-x-0 py-3'>
                Created with{' '}
                <svg
                    className='h-6 w-6 text-red-500 mx-1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                >
                    <path
                        fill-rule='evenodd'
                        d='M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z'
                        clip-rule='evenodd'
                    />
                </svg>{' '}
                <a href='https://primatech.id'>primatech.id</a>
            </footer>
        </div>
    );
};

export default Layout;
